var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b;
import { EventEmitter } from '@angular/core';
import { ApiRoutePlurality, HTTP_METHOD } from '../../../../defs/schema-static';
import { CONFIG_SCHEMA_ROUTE } from '../../../../defs/schema/meta/Config';
import { DEFAULT_SETTINGS, SETTING_LANGUAGE_VALUES, SETTINGS_FILTER_ROADMAP_VALUES, USER_SCHEMA_ROUTE, } from '../../../../defs/schema/public/Users';
import { AuthService } from '../auth/auth.service';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import * as i0 from "@angular/core";
import * as i1 from "../auth/auth.service";
import * as i2 from "../shared/http-rest/http-rest.service";
export var SETTINGS_LANGUAGE_LABELS = (_a = {},
    _a[SETTING_LANGUAGE_VALUES.EN] = 'English',
    _a[SETTING_LANGUAGE_VALUES.FR] = 'Français',
    _a);
export var SETTINGS_FILTER_ROADMAP_LABELS = (_b = {},
    _b[SETTINGS_FILTER_ROADMAP_VALUES.ALL] = 'all',
    _b[SETTINGS_FILTER_ROADMAP_VALUES.MY] = 'my',
    _b);
var SettingsService = (function () {
    function SettingsService(authService, httpRest) {
        var _this = this;
        this.authService = authService;
        this.httpRest = httpRest;
        this.settings = __assign({}, this.defaultSettings);
        this.settingsRestored = new EventEmitter();
        this.restore();
        this.authService.userChange.subscribe(function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2, this.getConfigSettings()];
        }); }); });
        (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2, this.getConfigSettings()];
        }); }); })();
    }
    SettingsService.prototype.restore = function () {
        Object.assign(this.settings, this.defaultSettings, (this.authService.user && this.authService.user.settings) || undefined);
        this.settingsRestored.emit(this.settings);
    };
    Object.defineProperty(SettingsService.prototype, "defaultSettings", {
        get: function () {
            return __assign({}, DEFAULT_SETTINGS, (this.applicationsSettings || {}));
        },
        enumerable: true,
        configurable: true
    });
    SettingsService.prototype.getConfigSettings = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.authService.user) return [3, 2];
                        _a = this;
                        return [4, this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.SINGULAR, CONFIG_SCHEMA_ROUTE, 'userSettings')
                                .toPromise()];
                    case 1:
                        _a.applicationsSettings = _b.sent();
                        return [3, 3];
                    case 2:
                        this.applicationsSettings = undefined;
                        _b.label = 3;
                    case 3:
                        this.restore();
                        return [2];
                }
            });
        });
    };
    SettingsService.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!this.authService.user) {
                    return [2, undefined];
                }
                return [2, this.httpRest
                        .post(USER_SCHEMA_ROUTE, {
                        id: this.authService.user.id,
                        settings: this.settings,
                    })
                        .toPromise()];
            });
        });
    };
    SettingsService.ngInjectableDef = i0.defineInjectable({ factory: function SettingsService_Factory() { return new SettingsService(i0.inject(i1.AuthService), i0.inject(i2.HttpRestService)); }, token: SettingsService, providedIn: "root" });
    return SettingsService;
}());
export { SettingsService };
