var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { getConfigKeys } from '../../../../defs/businessRules';
import { ApiRoutePlurality, HTTP_METHOD } from '../../../../defs/schema-static';
import { TRANSLATIONS_SCHEMA_ROUTE } from '../../../../defs/schema/meta/Translations';
import { SETTING_LANGUAGE_VALUES, SETTING_THEME_VALUES, SETTINGS } from '../../../../defs/schema/public/Users';
import { AuthService } from '../auth/auth.service';
import { ModalComponent } from '../modal/modal.component';
import { ConfigService } from '../shared/config/config.service';
import { ControlFlowService } from '../shared/control-flow/control-flow.service';
import { HttpRestService } from '../shared/http-rest/http-rest.service';
import { ShortcutHandlerService } from '../shared/shortcut-handler/shortcut-handler.service';
import { TOAST_TYPE, ToastService } from '../shared/toast/toast.service';
import { SETTINGS_LANGUAGE_LABELS, SettingsService } from './settings.service';
var SettingsComponent = (function (_super) {
    __extends(SettingsComponent, _super);
    function SettingsComponent(shortcutHandlerService, changeDetectorRef, controlFlowService, settingsService, translate, toastService, httpRest, configService, authService, ngSelectConfig) {
        var _this = _super.call(this, shortcutHandlerService, controlFlowService, changeDetectorRef) || this;
        _this.shortcutHandlerService = shortcutHandlerService;
        _this.changeDetectorRef = changeDetectorRef;
        _this.settingsService = settingsService;
        _this.translate = translate;
        _this.toastService = toastService;
        _this.httpRest = httpRest;
        _this.configService = configService;
        _this.authService = authService;
        _this.ngSelectConfig = ngSelectConfig;
        _this.settings = _this.settingsService.settings;
        _this.SETTINGS = SETTINGS;
        _this.SETTING_THEME_VALUES_VALUES = Object.values(SETTING_THEME_VALUES);
        _this.SETTINGS_LANGUAGE_LABELS = SETTINGS_LANGUAGE_LABELS;
        return _this;
    }
    SettingsComponent.prototype.ngOnChanges = function (changes) {
        _super.prototype.ngOnChanges.call(this, changes);
        if (changes.show && changes.show.currentValue) {
            this.oldLanguageSetting = this.settingsService.settings[SETTINGS.LANGUAGE];
        }
    };
    SettingsComponent.prototype.getAvailableLanguages = function () {
        var _this = this;
        return Object.values(SETTING_LANGUAGE_VALUES).filter(function (lang) {
            return (_this.enabledI18n &&
                _this.enabledI18n.findIndex(function (enabled) { return enabled.toLowerCase() === lang.toLowerCase(); }) > -1);
        });
    };
    SettingsComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var config, enabledI18n, language;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.authService.user) {
                            return [2];
                        }
                        return [4, this.configService.get()];
                    case 1:
                        config = _a.sent();
                        enabledI18n = getConfigKeys(config, ['enabledI18n']).enabledI18n;
                        this.enabledI18n = enabledI18n;
                        language = this.settingsService.settings[SETTINGS.LANGUAGE];
                        if (language &&
                            this.enabledI18n.findIndex(function (e) { return e.toUpperCase() === language || e.toLowerCase() === language; }) > -1) {
                            this.translate.use(language);
                        }
                        else if (!language ||
                            this.enabledI18n.indexOf(this.translate.getBrowserLang().toLowerCase()) > -1) {
                            this.translate.use(this.translate.getBrowserLang().toUpperCase());
                        }
                        else {
                            this.translate.setDefaultLang(this.enabledI18n[0].toUpperCase());
                            this.translate.use(this.enabledI18n[0].toUpperCase());
                        }
                        this.translate.onLangChange.subscribe(function (event) {
                            if (!language) {
                                return;
                            }
                            _this.translate.get('not_found').subscribe(function (e) { return (_this.ngSelectConfig.notFoundText = e); });
                            _this.translate.get('placeholder_start_typing').subscribe(function (e) { return (_this.ngSelectConfig.typeToSearchText = e); });
                            _this.httpRest
                                ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TRANSLATIONS_SCHEMA_ROUTE, "" + language.toLowerCase())
                                .subscribe(function (translations) {
                                translations.map(function (translation) {
                                    var _a;
                                    var c = (_a = {},
                                        _a[translation.key] = translation.value,
                                        _a);
                                    _this.translate.setTranslation(language, c, true);
                                });
                            });
                        });
                        this.httpRest
                            ._request(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, TRANSLATIONS_SCHEMA_ROUTE, "" + this.translate.currentLang.toLowerCase())
                            .subscribe(function (translations) {
                            translations.map(function (translation) {
                                var _a;
                                var c = (_a = {},
                                    _a[translation.key] = translation.value,
                                    _a);
                                _this.translate.setTranslation(_this.translate.currentLang, c, true);
                            });
                            _this.translate.get('not_found').subscribe(function (e) { return (_this.ngSelectConfig.notFoundText = e); });
                        });
                        this.oldLanguageSetting = language;
                        SettingsComponent.setTheme(this.settingsService.settings[SETTINGS.THEME]);
                        return [2];
                }
            });
        });
    };
    SettingsComponent.setTheme = function (theme) {
        var bodyElement = document.querySelector('body');
        if (!bodyElement.classList.contains("theme-" + theme)) {
            bodyElement.className = '';
            bodyElement.classList.add("theme-" + theme);
        }
    };
    SettingsComponent.prototype.confirm = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        [SETTINGS.THEME, SETTINGS.EMAIL_NOTIFICATIONS].map(function (setting) { return (_this.authService.user.settings[setting] = _this.settings[setting]); });
                        return [4, this.settingsService.save()];
                    case 1:
                        _a.sent();
                        this.translate.use(this.settingsService.settings[SETTINGS.LANGUAGE]);
                        if (this.settingsService.settings[SETTINGS.LANGUAGE] !== this.oldLanguageSetting) {
                            this.toastService.show({
                                type: TOAST_TYPE.INFO,
                                text: 'toast_refresh_translation',
                                appLevel: true,
                                callbackText: 'refresh',
                                callback: function () {
                                    window.location.reload(true);
                                },
                            });
                        }
                        this.showChange.emit((this.show = false));
                        return [2];
                }
            });
        });
    };
    SettingsComponent.prototype.cancel = function () {
        this.settingsService.restore();
        SettingsComponent.setTheme(this.settingsService.settings[SETTINGS.THEME]);
        this.showChange.emit((this.show = false));
    };
    return SettingsComponent;
}(ModalComponent));
export { SettingsComponent };
