<router-outlet></router-outlet>

<app-modal
    [preventDismiss]="true"
    [(show)]="authService.modalConfirmNumber.show"
    [title]="authService.modalConfirmNumber.title | translate"
>
    <ng-template appModalBody>
        <div [innerHTML]="authService.modalConfirmNumber.content"></div>
        <form id="authForm1" class="clr-form">
            <clr-input-container>
                <label translate>placeholder_code</label>
                <input
                    [sharedAutofocus]="authService.modalConfirmNumber.show"
                    type="text"
                    name="confirm"
                    autocomplete="confirm"
                    clrInput
                    [(ngModel)]="authService.modalConfirmNumber.value"
                    required
                    class="clr-input"
                    [pattern]="PATTERN_NUMBER.source"
                />
            </clr-input-container>
        </form>
    </ng-template>
    <ng-template appModalFooter>
        <button
            *ngIf="authService.modalConfirmNumber.cancelable || !!authService.modalConfirmNumber.cancel"
            class="btn btn-secondary"
            (click)="authService.modalConfirmNumber.show = false"
        >
            {{ 'form_cancel' | translate }}
        </button>
        <button
            *ngIf="!!authService.modalConfirmNumber.callback"
            form="authForm1"
            class="btn btn-primary"
            (click)="authService.modalConfirmNumber.callback(authService.modalConfirmNumber.value)"
            [disabled]="!authService.modalConfirmNumber.value"
        >
            {{ authService.modalConfirmNumber.callbackLabel | translate }}
        </button>
    </ng-template>
</app-modal>

<app-modal [(show)]="authService.modalAlert.show" [title]="authService.modalAlert.title | translate">
    <ng-template appModalBody> <div [innerHTML]="authService.modalAlert.content"></div> </ng-template>
    <ng-template appModalFooter>
        <button class="btn btn-primary" (click)="authService.modalAlert.showInline = false">
            {{ 'form_validate' | translate }}
        </button>
    </ng-template>
</app-modal>

<app-modal
    [preventDismiss]="true"
    [(show)]="authService.modalUpdate.show"
    [title]="authService.modalUpdate.title | translate"
>
    <ng-template appModalBody>
        <div class="alert alert-danger" role="alert" *ngIf="authService.modalUpdate.error">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                    </div>
                    <span class="alert-text" translate>{{ authService.modalUpdate.error }}</span>
                </div>
            </div>
        </div>
        <form id="authForm2" class="clr-form">
            <clr-input-container>
                <label translate>password</label>
                <input
                    [sharedAutofocus]="authService.modalUpdate.show"
                    type="password"
                    name="confirm"
                    autocomplete="confirm"
                    clrInput
                    [(ngModel)]="authService.modalUpdate.value"
                    required
                    class="clr-input"
                />
            </clr-input-container>
            <clr-input-container>
                <label translate>confirm_password</label>
                <input
                    type="password"
                    name="confirmPassword"
                    autocomplete="confirmPassword"
                    clrInput
                    [(ngModel)]="authService.modalUpdate.confirmPassword"
                    required
                    class="clr-input"
                />
            </clr-input-container>
        </form>
        <div
            class="alert alert-danger"
            role="alert"
            *ngIf="
                authService.modalUpdate.value &&
                authService.modalUpdate.confirmPassword &&
                authService.modalUpdate.value !== authService.modalUpdate.confirmPassword
            "
        >
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                    </div>
                    <span class="alert-text" translate>signin_error_password_confirmation</span>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template appModalFooter>
        <button class="btn btn-secondary" (click)="authService.modalUpdate.cancel()">
            {{ 'form_cancel' | translate }}
        </button>
        <button
            form="authForm2"
            class="btn btn-primary"
            [disabled]="
                !authService.modalUpdate.value ||
                !authService.modalUpdate.confirmPassword ||
                authService.modalUpdate.value !== authService.modalUpdate.confirmPassword
            "
            (click)="authService.modalUpdate.callback(authService.modalUpdate.value)"
        >
            {{ authService.modalUpdate.callbackLabel | translate }}
        </button>
    </ng-template>
</app-modal>

<app-modal
    [preventDismiss]="true"
    [(show)]="authService.modalConfirmPass.show"
    [title]="authService.modalConfirmPass.title | translate"
>
    <ng-template appModalBody>
        <div class="alert alert-danger" role="alert" *ngIf="authService.modalConfirmPass.error">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                    </div>
                    <span class="alert-text" translate>{{ authService.modalConfirmPass.error }}</span>
                </div>
            </div>
        </div>
        <form id="authForm3" class="clr-form">
            <clr-input-container>
                <label translate>placeholder_code</label>
                <input
                    [sharedAutofocus]="authService.modalConfirmPass.show"
                    type="text"
                    name="code"
                    autocomplete="code"
                    clrInput
                    [placeholder]="'placeholder_code' | translate"
                    [(ngModel)]="authService.modalConfirmPass.value"
                    required
                    class="clr-input clr-col-12"
                    [pattern]="PATTERN_NUMBER.source"
                />
            </clr-input-container>
            <clr-input-container>
                <label translate>password</label>
                <input
                    type="password"
                    name="password"
                    autocomplete="password"
                    clrInput
                    [(ngModel)]="authService.modalConfirmPass.valueBis"
                    required
                    class="clr-input clr-col-12"
                />
            </clr-input-container>
            <clr-input-container>
                <label translate>confirm_password</label>
                <input
                    type="password"
                    name="confirmPassword"
                    autocomplete="confirmPassword"
                    clrInput
                    [(ngModel)]="authService.modalConfirmPass.confirmPassword"
                    required
                    class="clr-input clr-col-12"
                />
            </clr-input-container>
        </form>
        <div
            class="alert alert-danger"
            role="alert"
            *ngIf="
                authService.modalConfirmPass.valueBis &&
                authService.modalConfirmPass.confirmPassword &&
                authService.modalConfirmPass.valueBis !== authService.modalConfirmPass.confirmPassword
            "
        >
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                    </div>
                    <span class="alert-text" translate>signin_error_password_confirmation</span>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template appModalFooter>
        <button
            *ngIf="authService.modalConfirmPass.cancelable || !!authService.modalConfirmPass.cancel"
            class="btn btn-secondary"
            (click)="authService.modalConfirmPass.show = false"
        >
            {{ 'form_cancel' | translate }}
        </button>
        <button
            form="authForm3"
            class="btn btn-primary"
            [disabled]="
                !authService.modalConfirmPass.value ||
                !authService.modalConfirmPass.valueBis ||
                !authService.modalConfirmPass.confirmPassword ||
                authService.modalConfirmPass.valueBis !== authService.modalConfirmPass.confirmPassword
            "
            (click)="
                authService.modalConfirmPass.callback(
                    authService.modalConfirmPass.value,
                    authService.modalConfirmPass.valueBis
                )
            "
        >
            {{ authService.modalConfirmPass.callbackLabel | translate }}
        </button>
    </ng-template>
</app-modal>
