import {ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NgSelectConfig} from '@ng-select/ng-select';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {getConfigKeys} from '../../../../defs/businessRules';
import {ApiRoutePlurality, HTTP_METHOD} from '../../../../defs/schema-static';
import {ITranslations, TRANSLATIONS_SCHEMA_ROUTE} from '../../../../defs/schema/meta/Translations';
import {ISettings, SETTING_LANGUAGE_VALUES, SETTING_THEME_VALUES, SETTINGS} from '../../../../defs/schema/public/Users';
import {AuthService} from '../auth/auth.service';
import {ModalComponent} from '../modal/modal.component';
import {ConfigService} from '../shared/config/config.service';
import {ControlFlowService} from '../shared/control-flow/control-flow.service';
import {HttpRestService} from '../shared/http-rest/http-rest.service';
import {ShortcutHandlerService} from '../shared/shortcut-handler/shortcut-handler.service';
import {TOAST_TYPE, ToastService} from '../shared/toast/toast.service';
import {SETTINGS_LANGUAGE_LABELS, SettingsService} from './settings.service';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent extends ModalComponent implements OnChanges, OnInit {
    public settings: ISettings = this.settingsService.settings;
    private oldLanguageSetting: string;
    private enabledI18n: SETTING_LANGUAGE_VALUES[];

    public constructor(
        protected shortcutHandlerService: ShortcutHandlerService,
        protected changeDetectorRef: ChangeDetectorRef,
        controlFlowService: ControlFlowService,
        private readonly settingsService: SettingsService,
        private readonly translate: TranslateService,
        private readonly toastService: ToastService,
        private readonly httpRest: HttpRestService,
        private readonly configService: ConfigService,
        private readonly authService: AuthService,
        private readonly ngSelectConfig: NgSelectConfig
    ) {
        super(shortcutHandlerService, controlFlowService, changeDetectorRef);
    }

    public ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        if (changes.show && changes.show.currentValue) {
            this.oldLanguageSetting = this.settingsService.settings[SETTINGS.LANGUAGE];
        }
    }

    public getAvailableLanguages(): string[] {
        return Object.values(SETTING_LANGUAGE_VALUES).filter((lang) => {
            return (
                this.enabledI18n &&
                this.enabledI18n.findIndex((enabled) => enabled.toLowerCase() === lang.toLowerCase()) > -1
            );
        });
    }

    public async ngOnInit() {
        if (!this.authService.user) {
            return;
        }

        const config = await this.configService.get();

        const {enabledI18n} = getConfigKeys(config, ['enabledI18n']);
        this.enabledI18n = enabledI18n;

        const language = this.settingsService.settings[SETTINGS.LANGUAGE];

        if (
            language &&
            this.enabledI18n.findIndex((e) => e.toUpperCase() === language || e.toLowerCase() === language) > -1
        ) {
            this.translate.use(language);
        } else if (
            !language ||
            this.enabledI18n.indexOf(this.translate.getBrowserLang().toLowerCase() as SETTING_LANGUAGE_VALUES) > -1
        ) {
            this.translate.use(this.translate.getBrowserLang().toUpperCase());
        } else {
            this.translate.setDefaultLang(this.enabledI18n[0].toUpperCase());
            this.translate.use(this.enabledI18n[0].toUpperCase());
        }

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            if (!language) {
                return;
            }

            this.translate.get('not_found').subscribe((e) => (this.ngSelectConfig.notFoundText = e));
            this.translate.get('placeholder_start_typing').subscribe((e) => (this.ngSelectConfig.typeToSearchText = e));

            this.httpRest
                ._request<ITranslations[]>(
                    HTTP_METHOD.GET,
                    ApiRoutePlurality.PLURAL,
                    TRANSLATIONS_SCHEMA_ROUTE,
                    `${language.toLowerCase()}`
                )
                .subscribe((translations) => {
                    translations.map((translation) => {
                        const c = {
                            [translation.key]: translation.value,
                        };

                        this.translate.setTranslation(language, c, true);
                    });
                });
        });

        // loading custom translations
        this.httpRest
            ._request<ITranslations[]>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.PLURAL,
                TRANSLATIONS_SCHEMA_ROUTE,
                `${this.translate.currentLang.toLowerCase()}`
            )
            .subscribe((translations) => {
                translations.map((translation) => {
                    const c = {
                        [translation.key]: translation.value,
                    };

                    this.translate.setTranslation(this.translate.currentLang, c, true);
                });
                this.translate.get('not_found').subscribe((e) => (this.ngSelectConfig.notFoundText = e));
            });

        this.oldLanguageSetting = language;

        SettingsComponent.setTheme(this.settingsService.settings[SETTINGS.THEME]);
    }

    public static setTheme(theme: string) {
        const bodyElement = document.querySelector('body');
        if (!bodyElement.classList.contains(`theme-${theme}`)) {
            bodyElement.className = '';
            bodyElement.classList.add(`theme-${theme}`);
        }
    }

    public async confirm() {
        [SETTINGS.THEME, SETTINGS.EMAIL_NOTIFICATIONS].map(
            (setting) => (this.authService.user.settings[setting] = this.settings[setting])
        );

        await this.settingsService.save();
        this.translate.use(this.settingsService.settings[SETTINGS.LANGUAGE]);
        if (this.settingsService.settings[SETTINGS.LANGUAGE] !== this.oldLanguageSetting) {
            // loading custom translations

            this.toastService.show({
                type: TOAST_TYPE.INFO,
                text: 'toast_refresh_translation',
                appLevel: true,
                callbackText: 'refresh',
                callback: () => {
                    // tslint:disable-next-line: deprecation
                    window.location.reload(true);
                },
            });
        }
        this.showChange.emit((this.show = false));
    }

    public cancel() {
        this.settingsService.restore();
        SettingsComponent.setTheme(this.settingsService.settings[SETTINGS.THEME]);
        this.showChange.emit((this.show = false));
    }

    public readonly SETTINGS = SETTINGS;
    public readonly SETTING_THEME_VALUES_VALUES = Object.values(SETTING_THEME_VALUES);
    public readonly SETTINGS_LANGUAGE_LABELS = SETTINGS_LANGUAGE_LABELS;
}
