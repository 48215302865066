import {EventEmitter, Injectable, Output} from '@angular/core';
import {ApiRoutePlurality, HTTP_METHOD} from '../../../../defs/schema-static';
import {CONFIG_SCHEMA_ROUTE} from '../../../../defs/schema/meta/Config';
import {
    DEFAULT_SETTINGS,
    ISettings,
    IUser,
    SETTING_LANGUAGE_VALUES,
    SETTINGS_FILTER_ROADMAP_VALUES,
    USER_SCHEMA_ROUTE,
} from '../../../../defs/schema/public/Users';
import {AuthService} from '../auth/auth.service';
import {HttpRestService} from '../shared/http-rest/http-rest.service';

export const SETTINGS_LANGUAGE_LABELS: {[setting in SETTING_LANGUAGE_VALUES]: string} = {
    [SETTING_LANGUAGE_VALUES.EN]: 'English',
    [SETTING_LANGUAGE_VALUES.FR]: 'Français',
};

export const SETTINGS_FILTER_ROADMAP_LABELS: {[setting in SETTINGS_FILTER_ROADMAP_VALUES]: string} = {
    [SETTINGS_FILTER_ROADMAP_VALUES.ALL]: 'all',
    [SETTINGS_FILTER_ROADMAP_VALUES.MY]: 'my',
};

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    public applicationsSettings: Partial<ISettings>;
    public readonly settings: ISettings = {...this.defaultSettings};

    @Output()
    public settingsRestored = new EventEmitter<ISettings>();

    public constructor(protected authService: AuthService, protected httpRest: HttpRestService) {
        this.restore();
        this.authService.userChange.subscribe(async () => this.getConfigSettings());

        (async () => this.getConfigSettings())();
    }

    public restore() {
        Object.assign(
            this.settings,
            this.defaultSettings,
            (this.authService.user && this.authService.user.settings) || undefined
        );
        this.settingsRestored.emit(this.settings);
    }

    public get defaultSettings(): ISettings {
        return {
            ...DEFAULT_SETTINGS,
            ...(this.applicationsSettings || {}),
        };
    }

    public async getConfigSettings() {
        if (this.authService.user) {
            this.applicationsSettings = await this.httpRest
                ._request(HTTP_METHOD.GET, ApiRoutePlurality.SINGULAR, CONFIG_SCHEMA_ROUTE, 'userSettings')
                .toPromise();
        } else {
            this.applicationsSettings = undefined;
        }

        this.restore();
    }

    public async save() {
        if (!this.authService.user) {
            return undefined;
        }

        return this.httpRest
            .post<IUser>(USER_SCHEMA_ROUTE, {
                id: this.authService.user.id,
                settings: this.settings,
            })
            .toPromise();
    }
}
