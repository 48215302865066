import {Component} from '@angular/core';
import {PATTERN_NUMBER} from '../../../../defs/schema-static';
import {AuthService} from './auth.service';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
})
export class AuthComponent {
    public constructor(public authService: AuthService) {}

    public readonly PATTERN_NUMBER = PATTERN_NUMBER;
}
