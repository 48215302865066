import {ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';
import {NgSelectComponent} from '@ng-select/ng-select';
import {FormGroup} from 'ngx-strongly-typed-forms';
import {concat, Observable, of, Subject, Subscription} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import * as SemVer from 'semver';
import {overEstimatedTime} from '../../../../../defs/businessRules';
import {
    ApiRoutePlurality,
    DECIMAL_RADIX,
    HTTP_METHOD,
    ISO_DATE_FORMAT,
    MAX_LENGTH_TASK_NAME,
    PATTERN_TIME,
    ProjectStatusType,
    ReleaseStateType,
    RIGHTS,
} from '../../../../../defs/schema-static';
import {EMPLOYEE_FIELD, EMPLOYEE_SCHEMA_ROUTE, IEmployee} from '../../../../../defs/schema/public/Employees';
import {IMilestone, MILESTONE_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Milestones';
import {IProject, PROJECT_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Projects';
import {IDisplayTag, ITag, TAG_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Tags';
import {ITask, TASK_SCHEMA_ROUTE} from '../../../../../defs/schema/public/Tasks';
import {USER_FIELD} from '../../../../../defs/schema/public/Users';
import {AddBlockersService} from '../../add-blockers-modal/add-blockers.service';
import {getRandomColorHex, isColorDark} from '../../app-static';
import {AuthService} from '../../auth/auth.service';
import {ConfigService} from '../../shared/config/config.service';
import {EMPLOYEE_MEMBER_TYPE} from '../../shared/editable-employee-label/editable-employee-label.component';
import {HttpRestService} from '../../shared/http-rest/http-rest.service';
import {MomentService} from '../../shared/moment/moment.service';
import {SHORTCUT_MISC} from '../../shared/shortcut-handler/shortcut-handler.service';
import {AddTaskFormField, FormsAddTaskService, IAddTaskFormModal} from './add-task.service';

@Component({
    selector: 'app-forms-add-task',
    templateUrl: './add-task.component.html',
    styleUrls: ['./add-task.component.scss'],
})
export class FormsAddTaskComponent implements OnDestroy {
    @Input()
    public show: boolean;

    @Input()
    public noParent: boolean;

    public activeProjects: IProject[];
    public tasks: ITask[];
    public tags: IDisplayTag[];
    public released: IMilestone[];
    public releases: IMilestone[];
    public staged: IMilestone[];
    public unreleased: IMilestone[];

    @Input() public employees: IEmployee[];

    public currentRelease: IMilestone;
    public parentTask: ITask;

    public assigned: IEmployee[] = [];
    private onSubmit: Subscription;

    public form: FormGroup<IAddTaskFormModal> = FormsAddTaskService.getFormGroup();

    @ViewChild('commentInput')
    public commentInput: ElementRef;

    @ViewChild('nameInput')
    public nameInput: ElementRef;

    @ViewChild('assignedSelect')
    public assignedSelect: ElementRef;

    private project: IProject;

    @ViewChild('beginDateInputElement') public beginDateInputElement: ElementRef;
    @ViewChild('endDateInputElement') public endDateInputElement: ElementRef;

    public beginDate: Date;
    public endDate: Date;

    public tasksParent: Observable<any[] | ITask[]>;
    public tasksParentLoading = false;
    public tasksParent$ = new Subject<string>();

    public constructor(
        private readonly httpRest: HttpRestService,
        public addBlockers: AddBlockersService,
        private readonly formsAddTaskService: FormsAddTaskService,
        public authService: AuthService,
        private readonly momentService: MomentService,
        private readonly cdRef: ChangeDetectorRef,
        private readonly configService: ConfigService
    ) {
        this.tasks = [];
        this.tags = [];
        this.released = [];
        this.releases = [];
        this.staged = [];
        this.unreleased = [];
        this.parentTask = {name: ''} as ITask;
    }

    // tslint:disable-next-line: prefer-function-over-method no-empty
    public ngOnDestroy() {}

    public loadTasks(term: string) {
        return this.httpRest._request<ITask[]>(
            HTTP_METHOD.POST,
            ApiRoutePlurality.PLURAL,
            TASK_SCHEMA_ROUTE,
            'searchTasks',
            {
                search: term,
                projectId: this.form.value.project,
            }
        );
    }

    private loadParentTasks() {
        this.tasksParent = concat(
            of([]), // default items
            this.tasksParent$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => (this.tasksParentLoading = true)),
                switchMap((term) => {
                    if (!term || term === '') {
                        this.tasksParentLoading = false;

                        return of([]);
                    }

                    return this.loadTasks(term).pipe(
                        catchError(() => of([])), // empty list on error
                        tap(() => (this.tasksParentLoading = false))
                    );
                })
            )
        );
    }

    public getInputNameLength() {
        return this.nameInput.nativeElement.value.length;
    }
    public employeesNotAssigned: IEmployee[] = [];
    public getNotAssigned() {
        if (!this.employees || !this.activeProjects) {
            return [];
        }

        const project = this.activeProjects.find((p) => p.id === +this.form.value.project);

        if (!project) {
            return [];
        }

        return this.employees
            .filter((e) => project.projectMembers.findIndex((pm) => pm.employeeId === e.id) > -1)
            .filter((e) => this.assigned.findIndex((_e) => _e.id === e.id) === -1)
            .sort((a, b) => {
                return a.user.name.localeCompare(b.user.name);
            });
    }

    public addAssigned() {
        if (this.assignedSelect.nativeElement.value === '') {
            return;
        }

        const emp = this.employees.find(
            (e) => e.id === parseInt(this.assignedSelect.nativeElement.value, DECIMAL_RADIX)
        );
        this.assigned.push(emp);
        this.form.patchValue({
            assigned: this.assigned,
        });
    }

    public getEmployee(id: number): IEmployee {
        return this.employees.find((e) => e.id === id);
    }

    public removeAssigned(emp: IEmployee) {
        this.form.value.assigned = this.form.value.assigned.filter((e) => e.id !== emp.id);
        this.form.patchValue({
            assigned: this.form.value.assigned,
        });
    }
    public alreadyInit = false;
    public selectedTagCopy: any[] = [];
    public load(params?: {projectId?: number; parentTask?: ITask; name?: string; comment?: string; tags?: ITag[]}) {
        if (this.alreadyInit) {
            return;
        }
        this.alreadyInit = true;
        this.clearParentTask();

        this.assigned = [];
        if (params && params.parentTask) {
            Object.assign(this.parentTask, {
                name: params.parentTask.name,
                id: params.parentTask.id,
                code: params.parentTask.code,
            });
            this.form.reset({
                tracked: true,
                bug: false,
                urgent: false,
                project: params.parentTask.projectId,
                parentTaskId: params.parentTask.id,
                releaseId: 0,
                assigned: this.assigned,
            });
        } else if (params && params.name) {
            this.form.reset({
                name: params.name,
                comment: params.comment,
                tracked: true,
                bug: false,
                urgent: false,
                parentTaskId: null,
                releaseId: 0,
                assigned: this.assigned,
            });
        } else {
            this.form.reset({
                tracked: true,
                bug: false,
                urgent: false,
                parentTaskId: null,
                releaseId: 0,
                assigned: this.assigned,
            });
        }

        this.form.value.release = true;
        this.form.value.parentTask = true;

        const pattern = Validators.pattern(PATTERN_TIME);

        this.form.controls.time.setValidators([pattern]);

        if (!this.employees) {
            this.httpRest
                ._request<IEmployee[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, EMPLOYEE_SCHEMA_ROUTE, 'list')
                .subscribe(async (employees) => {
                    this.employees = employees;
                    if (this.activeProjects) {
                        this.loadProject();
                    }
                });
        }

        this.httpRest
            ._request<IProject[]>(HTTP_METHOD.GET, ApiRoutePlurality.PLURAL, PROJECT_SCHEMA_ROUTE, 'light')
            .subscribe(async (projects) => {
                this.activeProjects = projects
                    .filter((p) => p.status === ProjectStatusType.OPEN)
                    .sort((a, b) => {
                        return a.obs.localeCompare(b.obs);
                    })
                    .concat(
                        projects
                            .filter((p) => p.status === ProjectStatusType.WAITING)
                            .sort((a, b) => {
                                return a.obs.localeCompare(b.obs);
                            })
                    )
                    .concat(
                        projects
                            .filter((p) => p.status === ProjectStatusType.CLOSE)
                            .sort((a, b) => {
                                return a.obs.localeCompare(b.obs);
                            })
                    );

                if (projects.length) {
                    if (params && params.parentTask) {
                        this.form.patchValue({
                            project: params.parentTask.projectId,
                        });
                    } else if (params && params.projectId) {
                        this.form.patchValue({
                            project: params.projectId,
                        });
                    } else {
                        this.form.patchValue({
                            project: parseInt(sessionStorage.getItem('projectId'), 10) || this.activeProjects[0].id,
                        });
                    }
                    this.loadProject();
                }
            });

        if (params && params.tags) {
            const formattedTags = params.tags.map((t) => {
                return {
                    text: t.text,
                    color: t.color,
                    branch: false,
                    projectId: this.form.value.project,
                    selected: true,
                };
            });
            this.formsAddTaskService.pendingTags = formattedTags;

            this.tags.push(...formattedTags);
            const transf = formattedTags.map((t) => {
                return {
                    disabled: undefined,
                    index: this.tags.findIndex((_t) => _t.text === t.text),
                    label: t.text,
                    value: t,
                    selected: true,
                };
            });

            this.selectedTagCopy = [...transf];

            // this.selectTag.selectedItems.push(...transf);

            // this.form.patchValue({
            //     tags: formattedTags,
            // });
            // this.formsAddTaskService.pendingTags.push(tag);
            // this.tags.push({...tag, selected: true});
        } else {
            this.formsAddTaskService.pendingTags = [];
        }
    }

    @ViewChild('selectTag')
    public selectTag: NgSelectComponent;

    public loadProject() {
        if (!this.activeProjects) {
            return;
        }
        this.project = this.activeProjects.find((project) => project.id === this.form.value.project);
        this.form.patchValue({assigned: []});
        this.employeesNotAssigned = this.getNotAssigned();
        this.httpRest
            ._request<ITask[]>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.PLURAL,
                TASK_SCHEMA_ROUTE,
                `loadProject/${this.form.value.project}`
            )
            .subscribe(async (tasks) => {
                this.tasks = tasks;
                this.loadParentTasks();
            });

        this.httpRest
            ._request<IMilestone[]>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.PLURAL,
                MILESTONE_SCHEMA_ROUTE,
                `release/project/${this.form.value.project}`
            )
            .subscribe((releases) => {
                this.released = releases.filter((release) => release.releaseState === ReleaseStateType.RELEASED);
                this.releases = releases.sort((a, b) => {
                    if (
                        a.releaseState === ReleaseStateType.IN_DEVELOPMENT &&
                        b.releaseState === ReleaseStateType.IN_DEVELOPMENT
                    ) {
                        return SemVer.compare(a.version, b.version);
                    } else if (
                        a.releaseState === ReleaseStateType.IN_DEVELOPMENT &&
                        b.releaseState !== ReleaseStateType.IN_DEVELOPMENT
                    ) {
                        return -1;
                    }

                    return SemVer.compare(b.version, a.version);
                });
                this.staged = releases.filter((release) => release.releaseState === ReleaseStateType.STAGED);
                this.unreleased = releases.filter(
                    (release) => release.releaseState === ReleaseStateType.IN_DEVELOPMENT
                );

                if (releases.length) {
                    let releaseId;
                    if (this.unreleased.length) {
                        releaseId = this.unreleased[0].id;
                    }
                    this.form.patchValue({
                        releaseId,
                        startDate: null,
                        endDate: null,
                    });
                    this.onReleaseIdChange({id: releaseId});
                } else {
                    const nowStr = this.momentService.moment();
                    this.form.patchValue({
                        releaseId: null,
                        startDate: nowStr.format(ISO_DATE_FORMAT),
                        endDate: nowStr.format(ISO_DATE_FORMAT),
                    });
                    this.onReleaseIdChange({id: null});
                }
            });

        this.httpRest
            ._request<ITag[]>(
                HTTP_METHOD.GET,
                ApiRoutePlurality.PLURAL,
                TAG_SCHEMA_ROUTE,
                `project/${this.form.value.project}`
            )
            .subscribe((tags) => {
                if (this.selectedTagCopy && this.selectedTagCopy.length > 0 && this.tags && this.tags.length > 0) {
                    tags.map((tag) => {
                        const tagExist = this.tags.find((t) => t.text === tag.text);
                        if (tagExist) {
                            tagExist.id = tag.id;
                            tag.color = tag.color;
                            tagExist.selected = true;
                            this.formsAddTaskService.pendingTags = this.formsAddTaskService.pendingTags.filter(
                                (pending) => {
                                    return pending.text !== tag.text;
                                }
                            );
                            if (!this.form.value.tags) {
                                this.form.value.tags = [];
                            }
                            this.form.value.tags.push(tag.id);
                            this.form.patchValue({
                                tags: this.form.value.tags,
                            });
                        } else {
                            this.tags.push({...tag, selected: false});
                        }
                    });
                } else {
                    this.form.value.tags = [];
                    this.formsAddTaskService.pendingTags = [];
                    this.form.patchValue({
                        tags: this.form.value.tags,
                    });
                    this.tags = tags.map((tag) => ({...tag, selected: false}));
                }

                if (this.selectedTagCopy && this.selectedTagCopy.length > 0) {
                    const transf = this.selectedTagCopy
                        .map((t) => {
                            return {
                                disabled: undefined,
                                index: this.tags.findIndex((_t) => _t.text === t.label),
                                label: t.label,
                                value: t.value,
                                selected: true,
                            };
                        })
                        .filter((a) => a.label);

                    this.selectedTagCopy = [...transf];
                    this.selectTag.clearModel();
                    this.selectTag.selectedItems.push(...transf);
                    this.formsAddTaskService.selectedTags = this.selectedTags().map((t) => t.id);
                    // this.form.patchValue({
                    //     tags: this.selectedTags().map((t) => t.id),
                    // });
                    this.cdRef.detectChanges();
                }
            });

        this.form.patchValue({
            parentTask: true,
            release: true,
        });

        // this.toggle();
        this.addBlockers.init(false, this.form);
    }

    public selectedTags() {
        return this.tags.filter((tag) => tag.selected);
    }

    public removeTag(tag: IDisplayTag) {
        this.form.patchValue({
            tags: this.form.value.tags.filter((_tag) => _tag !== tag.id),
        });
    }

    public updateTag(el: HTMLInputElement, tag: IDisplayTag) {
        const {...clone} = tag;
        this.httpRest.put<ITag>(TAG_SCHEMA_ROUTE, clone).subscribe((_tag) => {
            if (el) {
                this.tags.push({..._tag, selected: true});
                this.form.patchValue({
                    tags: this.tags.filter((selectedTag) => selectedTag.selected).map((selectedTag) => selectedTag.id),
                });
                el.value = '';
                el.focus();
            }
        });
    }

    public checkDates = () => {
        let beginDate: string;
        let endDate: string;
        let error = false;
        if (!this.beginDate && this.beginDateInputElement && this.beginDateInputElement.nativeElement.value !== '') {
            beginDate = this.beginDateInputElement.nativeElement.value;
            if (!this.momentService.moment(beginDate, ISO_DATE_FORMAT, true).isValid()) {
                error = true;
            }
        }
        if (!this.endDate && this.endDateInputElement && this.endDateInputElement.nativeElement.value !== '') {
            endDate = this.endDateInputElement.nativeElement.value;
            if (!this.momentService.moment(endDate, ISO_DATE_FORMAT, true).isValid()) {
                error = true;
            }
        }

        this.form.patchValue({
            startDate: this.beginDate ? this.momentService.moment(this.beginDate).toISOString() : null,
            endDate: this.endDate ? this.momentService.moment(this.endDate).toISOString() : null,
        });

        if ((!this.beginDate || !this.endDate) && !this.form.value.releaseId) {
            return false;
        } else if (
            this.momentService
                .moment(this.beginDate)
                .startOf('day')
                .isAfter(this.momentService.moment(this.endDate).endOf('day'))
        ) {
            return false;
        } else if (error) {
            return false;
        } else {
            return true;
        }
    };

    public onTagSubmit = async (text: string) => {
        const value = text.trim();
        if (!value) {
            return undefined;
        }

        const tag: IDisplayTag = {
            text: value,
            color: getRandomColorHex(),
            branch: false,
            projectId: this.form.value.project,
        };

        this.formsAddTaskService.pendingTags.push(tag);
        this.tags.push({...tag, selected: true});

        return tag;
    };

    public onRecursiveChange() {
        if (this.form.value.recursive) {
            this.onSubmit = this.formsAddTaskService.onSubmit.subscribe((task: ITask) => {
                this.tasks.unshift(task);
            });
        } else {
            this.onSubmit.unsubscribe();
        }
    }

    public toggle() {
        this.form.value.parentTask = true;
        // if (this.form.value.parentTask) {
        this.form.controls.parentTaskId.enable();
        // this.form.controls.parentTaskId.setValidators([Validators.required]);
        this.form.controls.parentTaskId.updateValueAndValidity();
        this.clearParentTask();
        // } else {
        //     this.form.controls.parentTaskId.disable();
        //     this.form.controls.parentTaskId.clearValidators();
        //     this.form.patchValue({
        //         parentTaskId: null,
        //     });
        //     this.form.controls.parentTaskId.updateValueAndValidity();
        // }
    }

    public clearParentTask() {
        Object.assign(this.parentTask, {
            name: '',
            id: null,
        });
        this.form.patchValue({
            parentTaskId: null,
        });
        const el = document.getElementById('parentTaskId');
        if (el) {
            window.setTimeout(() => {
                el.focus();
            }, 200);
        }
    }

    public toggleRelease() {
        if (this.form.value.releaseId) {
            // this.form.controls.startDate.disable();
            this.form.controls.startDate.clearValidators();
            // this.form.controls.endDate.disable();
            this.form.controls.endDate.clearValidators();
            this.form.patchValue({
                startDate: null,
                endDate: null,
            });
            this.beginDate = null;
            this.endDate = null;
        } else {
            // this.form.controls.startDate.enable();
            this.form.controls.startDate.setValidators([Validators.required]);
            // this.form.controls.endDate.enable();
            this.form.controls.endDate.setValidators([Validators.required]);
            const nowStr: string = this.momentService.moment().format(ISO_DATE_FORMAT);
            this.form.patchValue({
                startDate: this.form.value.startDate || nowStr,
                endDate: this.form.value.endDate || nowStr,
            });
        }
        this.form.controls.startDate.updateValueAndValidity();
        this.form.controls.endDate.updateValueAndValidity();
    }

    public onParentChange($event: Partial<ITask> = null) {
        this.form.patchValue({parentTaskId: $event ? $event.id : null});
    }

    public onReleaseIdChange($event: Partial<IMilestone> = null) {
        const nowStr = this.momentService.moment();
        const currentValueStart = this.form.value.startDate || nowStr.format(ISO_DATE_FORMAT);
        const currentValueEnd = this.form.value.endDate || nowStr.format(ISO_DATE_FORMAT);

        this.form.patchValue({
            releaseId: $event ? $event.id : null,
            startDate: $event ? null : currentValueStart,
            endDate: $event ? null : currentValueEnd,
        });

        if ($event && $event.id) {
            this.beginDate = null;
            this.beginDateInputElement.nativeElement.value = '';
            this.endDate = null;
            this.endDateInputElement.nativeElement.value = '';
        } else {
            this.beginDate = nowStr.toDate();
            this.endDate = nowStr.toDate();
        }

        this.toggleRelease();
        this.currentRelease = this.releases.find((ms) => ms.id === this.form.value.releaseId);
    }

    @HostListener('keypress', ['$event'])
    public jumpFromNameToComment($event: KeyboardEvent) {
        if (($event.key === 'Enter' || $event.key === 'Return') && $event.target === this.nameInput.nativeElement) {
            $event.preventDefault();
            this.commentInput.nativeElement.focus();
        }
    }

    public isTimeInvalid() {
        return this.form.controls.time.status === 'INVALID';
    }

    public checkValidationTime() {
        if (overEstimatedTime(this.configService.config, this.form.value.time)) {
            this.form.controls.time.setErrors({over53Weeks: true});
        }
    }

    public readonly AddTaskFormField = AddTaskFormField;
    public readonly MAX_LENGTH_TASK_NAME = MAX_LENGTH_TASK_NAME;
    public readonly isColorDark = isColorDark;

    public readonly RIGHTS = RIGHTS;

    public readonly SHORTCUT_MISC = SHORTCUT_MISC;
    public readonly EMPLOYEE_FIELD = EMPLOYEE_FIELD;
    public readonly USER_FIELD = USER_FIELD;
    public readonly EMPLOYEE_MEMBER_TYPE = EMPLOYEE_MEMBER_TYPE;
}
